import React from "react";

import { Header, Wrapper, AButton } from "../../../components";

import Illustration from "../../../assets/home-illustration.png";

import "./main-section.scss";

const MissionSection = ({ setCurrentSection, currentSection }) => {
    return (
        <section className="section section__home" id="home">    
            <Header currentSection={currentSection} setCurrentSection={setCurrentSection} />
            <Wrapper>
                <div className="home__container">
                    <div className="home__item">
                        <h1 className="home__heading title_bold">
                            Creating a product is
                            only half the job.
                            Are you advertising it right?
                        </h1>
                        <p className="home__text">At Flexpert we’ll take your marketing activities into our hands. Your product - offered to the right audience.</p>
                        <AButton href="#contact" color="var(--secondary)" background="var(--primary)" className="home__button">
                            Advertise My Product
                        </AButton>
                    </div>
                    <div className="home__item">
                        <img className="home__illustration" src={Illustration} alt="home" />
                    </div>
                </div>
            </Wrapper>
        </section>
    );
};

export default MissionSection;
