import React, { useState } from "react";

import HamburgerIcon from "../../assets/hamburger.svg";
import CloseIcon from "../../assets/close.svg";

import Logo from "../../assets/logo.svg";

const HamburberMenu = ({ setCurrentSection, currentSection }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClick = () => {
        setIsOpen((t) => !t);
    };

    const handleMenuClick = ({ target }) => {
        const [, section] = target.href.split("#");
        setCurrentSection(section);
        setIsOpen(false);
    };

    return (
        <div className="hamburger">
            <img src={HamburgerIcon} alt="logo" onClick={handleClick} className="hamburger__icon" />
            {isOpen && (
                <div className="hamburger__wrapper">
                    <div className="hamburger__header">
                        <div className="logo__container">
                            <img src={Logo} alt="logo" />
                            <span className="logo__title">Flexpert</span>
                        </div>
                        <img src={CloseIcon} alt="logo" onClick={handleClick} className="hamburger__icon hamburger__icon--no_padding" />
                    </div>
                    <ul className="hamburger__list nav__list">
                        <li className={`hamburger__item nav__item ${currentSection === "home" ? "nav__item--is_active" : ""}`}>
                            <a
                                href="#home"
                                onClick={handleMenuClick}
                            >
                                home
                            </a>
                        </li>
                        <li className={`hamburger__item nav__item ${currentSection === "about" ? "nav__item--is_active" : ""}`}>
                            <a
                                href="#about"
                                onClick={handleMenuClick}
                            >
                                about us
                            </a>
                        </li>
                        <li className={`hamburger__item nav__item ${currentSection === "services" ? "nav__item--is_active" : ""}`}>
                            <a
                                href="#services"
                                onClick={handleMenuClick}
                            >
                                services
                            </a>
                        </li>
                        <li className={`hamburger__item nav__item ${currentSection === "advantages" ? "nav__item--is_active" : ""}`}>
                            <a
                                href="#advantages"
                                onClick={handleMenuClick}
                            >
                                advantages
                            </a>
                        </li>
                        <li className={`hamburger__item nav__item ${currentSection === "careers" ? "nav__item--is_active" : ""}`}>
                            <a
                                href="#careers"
                                onClick={handleMenuClick}
                            >
                                careers
                            </a>
                        </li>
                        <li className={`hamburger__item nav__item ${currentSection === "contact" ? "nav__item--is_active" : ""}`}>
                            <a
                                href="#contact"
                                onClick={handleMenuClick}
                            >
                                contact us
                            </a>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
};

export default HamburberMenu;
