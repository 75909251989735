import React from "react";

import { Wrapper, Card } from "../../../components";

import ConversationIcon from "../../../assets/conversation.svg";
import ResumeIcon from "../../../assets/resume.svg";
import MoneyIcon from "../../../assets/money.svg";
import DiamondIcon from "../../../assets/diamond.svg";

import "./work-section.scss";

const MissionSection = () => {
    return (
        <section className="section section__work" id="advantages">
            <Wrapper>
                <h2 className="section__heading title_bold">Why you should work with us?</h2>
                <div className="section__cards">
                    <div className="card__group">
                        <Card className="section__card" title="Quality client service" alt="Conversation" logo={ConversationIcon}>
                            Deadlines met, questions answered.
                        </Card>
                        <Card className="section__card" title="KPI fulfillment" alt="resume" logo={ResumeIcon}>
                            Picked specifically for your business.
                        </Card>
                    </div>
                    <div className="card__group">
                        <Card
                            className="section__card"
                            title="The revenue
                        share model"
                            alt="money"
                            logo={MoneyIcon}
                        >
                            The right incentives implemented.
                        </Card>
                        <Card
                            className="section__card"
                            title="Unique
                        optimization
                        system"
                            alt="diamond"
                            logo={DiamondIcon}
                        >
                            Your product advertised efficiently.
                        </Card>
                    </div>
                </div>
            </Wrapper>
        </section>
    );
};

export default MissionSection;
