import React from "react";

import { Wrapper, Card } from "../../../components";

import TransparencyIcon from "../../../assets/transparency.svg";
import RespectIcon from "../../../assets/respect.svg";
import InnovationIcon from "../../../assets/innovation.svg";

import "./about-us-section.scss";

const MissionSection = () => {
    return (
        <section className="section section__about" id="about">
            <Wrapper>
                <h2 className="section__heading title_bold">about us</h2>
                <p className="section__text">Flexpert aims at offering more awesome projects to the audiences that will appreciate them to the fullest. We believe that there is no useless product, you’re only marketing it to the wrong people.</p>
                <p className="section__text">A group of enthusiasts with years of experience in the field, we do our best to engage all our expertise in what we do.</p>
                <h3 className="block__heading title_bold">Our values</h3>
                <div className="about__block">
                    <Card 
                        className="section__card"
                        center={true} 
                        title="Transparency"
                        logo={TransparencyIcon}
                        alt="Transparency"
                     >
                         You’re in the know of every step we take, and every result we receive.
                    </Card>
                    <Card 
                        className="section__card"
                        center={true} 
                        title="Respect"
                        logo={RespectIcon}
                        alt="Respect"
                     >
                         Your vision and ideas treated with consideration and a personal approach.
                    </Card>
                    <Card 
                        className="section__card"
                        center={true} 
                        title="Innovation"
                        logo={InnovationIcon}
                        alt="Innovation"
                     >
                         Your business met with new market data and marketing tactics.
                    </Card>
                </div>
            </Wrapper>
        </section>
    )
}

export default MissionSection;
