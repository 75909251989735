import React from 'react'

import "./Footer.scss";

const Footer = () => {
    return (
        <div className="footer">
            <div className="footer__text">
                Flexpert-FZCO, Silicon Oasis, Dubai, United Arab Emirates
                <p className="footer__info">
                    <span className="info__item">Email: <a href="mailto:info@flexpert.digital">info@flexpert.digital</a></span>
                    <span className="footer__separator separator"></span> 
                    <span className="info__item">Website: <a href="http://flexpert.digital/">flexpert.digital</a></span>
                </p> 
                All rights reserved. 2021
            </div> 
        </div>
    )
}

export default Footer;
