import React, { useState } from "react";
import isemail from "isemail";

import { TextField, ThemeProvider, createMuiTheme, Snackbar } from "@material-ui/core";

import { AButton } from "../";

import "./contact-form.scss";

const ContactForm = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [desc, setDesc] = useState("");
    const [message, setMessage] = useState("");
    const [not, setNot] = useState(false);
    const [notEnterd, setNotEnterd] = useState(false);

    const theme = createMuiTheme({
        palette: {
            primary: {
                main: "#FFDA00;",
            },
        },
    });

    const handleSumbit = () => {
        if (!isemail.validate(email)) {
            setNot(true);
            return;
        }
        if (name === "" || desc === "") {
            setNotEnterd(true);
            return;
        }

        const formData = new FormData();

        formData.set("text", desc);
        formData.set("email", email);
        formData.set("name", name);
        
        fetch( `${window.location.origin}/index.php`, {
            method: "POST", 
            body: formData
        });

        setMessage("Sent!");
    };

    return (
        <>
            <div className="form" id="contact">
                <h4 className="form__title title_bold">Contact us</h4>
                <p className="form__subtitle">Enjoy your business, leave the advertisement fuss to us!</p>
                <div className="form__inputs">
                    <ThemeProvider theme={theme}>
                        <TextField className="form__input" variant="outlined" label="Name" value={name} fullWidth onChange={({ target }) => setName(target.value)} />
                        <TextField className="form__input" variant="outlined" label="E-mail" type="email" value={email} fullWidth onChange={({ target }) => setEmail(target.value)} />
                        <TextField
                            className="form__input form__input--mul"
                            variant="outlined"
                            label="Product Desription"
                            value={desc}
                            multiline
                            fullWidth
                            onChange={({ target }) => setDesc(target.value)}
                            rowsMax={4}
                        />
                        <AButton className="form__button" border="2px solid var(--secondary)" color="black" onClick={handleSumbit}>
                            Contact Us
                        </AButton>
                    </ThemeProvider>
                </div>
            </div>
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={not}
                onClose={() => setNot(false)}
                autoHideDuration={2000}
                message="This is not valid email"
            />
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={notEnterd}
                onClose={() => setNotEnterd(false)}
                autoHideDuration={2000}
                message="Please enter data"
            />
            <Snackbar
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                open={message !== ""}
                onClose={() => setMessage("")}
                autoHideDuration={2000}
                message={message}
            />
        </>
    );
};

export default ContactForm;
