import React from 'react';

import Logo from "../../assets/logo.svg";

import { Navigation, Wrapper } from "../";

import "./header.scss";

const Header = ({ setCurrentSection, currentSection }) => {
    return (
        <Wrapper>
            <header className="header">
                    <div className="logo__container">
                        <img src={Logo} alt="logo"/>
                        <span className="logo__title">Flexpert</span>
                    </div>
                    <Navigation currentSection={currentSection} setCurrentSection={setCurrentSection}/>
            </header>
        </Wrapper>
    )
}

export default Header
