import React from "react";

import { Wrapper, ContactForm, Footer } from "../../../components";

import "./mission-section.scss";

const MissionSection = () => {
    return (
        <section className="section section__mission" id="careers">    
            <Wrapper>
                <div className="mission__container">
                    <h2 className="section__heading title_bold">Mission and Vision</h2>
                    <div className="section__our">
                        <div className="our_block">
                            <div className="our_block__title title_bold">
                            Our Mission
                            </div>
                            <div className="out_block__text">
                            Flexpert is aimed at helping businesses advertise their products to the proper audience.
                            </div>
                        </div>
                        <div className="our_block__separator"></div>
                        <div className="our_block">
                            <div className="our_block__title title_bold">
                            Our vision
                            </div>
                            <div className="out_block__text">
                            We believe that every product has its customer. The key is a strategic advertisement.
                            </div>
                        </div>
                    </div>
                    <div className="section__form">
                        <ContactForm />
                    </div>
                </div>
            </Wrapper>
            <Footer />
        </section>
    )
}

export default MissionSection;
