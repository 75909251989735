import React from "react";

import { Wrapper, Card, AButton } from "../../../components";

import UserAcquisitionIcon from "../../../assets/user-acquisition.svg";
import PromotionIcon from "../../../assets/promotion.svg";
import ChartIcon from "../../../assets/chart.svg";
import AnaliticsIcon from "../../../assets/analitics.svg";
import ApplicationIcon from "../../../assets/application.svg";

import "./services-section.scss";

const MissionSection = () => {
    return (
        <section className="section section__services" id="services">
            <Wrapper>
                <h2 className="section__heading title_bold">Services</h2>
                <div className="section__cards">
                    <Card className="section__card" title="User Acquisition" alt="Acquisition" logo={UserAcquisitionIcon}>
                        After running a few testing campaigns, analyzing them, and founding the ones that work best for your individual project, we attract 10k users directly to your product. No
                        suspicious traffic providers, only PPC campaigns picked specifically for your business.
                    </Card>
                    <Card className="section__card" title="Comprehensive Promotion" alt="statistics" logo={PromotionIcon}>
                        We know the market and how it works. With our team of professionals, we will help you to enter new markets, promote your applications to the top ranks, and make sure you will
                        reach your target audience with our every marketing activity.{" "}
                    </Card>
                    <Card className="section__card" title="Advertising on Facebook & Instagram" alt="social" logo={ChartIcon}>
                        We manage our clients’ Instagram and Facebook campaigns, running them based on people’s interests, preferences, and behavior. Such targeting allows us to help our client engage
                        more people with less advertising budget.{" "}
                    </Card>
                    <Card className="section__card" title="Influencer Marketing" alt="Chart" logo={AnaliticsIcon}>
                        The vast majority of people trust authorities - so we give them that. We advertise through a network of influencers on Instagram, YouTube, Snapchat, Twitch, TikTok, and
                        Twitter. The platform is chosen specifically for every business, depending on their target audience.{" "}
                    </Card>
                    <Card className="section__card" title="Mobile Retargeting" alt="Application" logo={ApplicationIcon}>
                        Increasing the number of new users is great, but we do not about engaging the ones who have already installed our client’s app and stopped using it. We put our expertise into
                        re-engaging and pushing them into interacting with the app on the regular basis.{" "}
                    </Card>
                </div>
                <AButton className="section__button" color="var(--white)" border="1px solid var(--primary)" href="#contact">
                    Start Working With Us
                </AButton>
            </Wrapper>
        </section>
    );
};

export default MissionSection;
