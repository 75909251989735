import React from 'react';
import "./Wrapper.scss";

const Wrapper = ({ children }) => {
    return (
        <div className="wrapper">
            {children}
        </div>
    )
}

export default Wrapper;
