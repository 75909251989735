import React from "react";

import HamburberMenu from "./HamburberMenu";

import "./navigation.scss";

const Navigation = ({ setCurrentSection, currentSection }) => {

    const handleClick = ({ target }) => {
        const [, section] = target.href.split("#");
        setCurrentSection(section);
    };

    return (
        <>
            <nav className="nav">
                <ul className="nav__list">
                    <li className={`nav__item ${currentSection === "home" ? "nav__item--is_active" : ""}`}>
                        <a href="#home" onClick={handleClick}>
                            home
                        </a>
                    </li>
                    <li className={`nav__item ${currentSection === "about" ? "nav__item--is_active" : ""}`}>
                        <a href="#about" onClick={handleClick}>
                            about us
                        </a>
                    </li>
                    <li className={`nav__item ${currentSection === "services" ? "nav__item--is_active" : ""}`}>
                        <a href="#services" onClick={handleClick}>
                            services
                        </a>
                    </li>
                    <li className={`nav__item ${currentSection === "advantages" ? "nav__item--is_active" : ""}`}>
                        <a href="#advantages" onClick={handleClick}>
                            advantages
                        </a>
                    </li>
                    <li className={`nav__item ${currentSection === "careers" ? "nav__item--is_active" : ""}`}>
                        <a href="#careers" onClick={handleClick}>
                            careers
                        </a>
                    </li>
                    <li className={`nav__item ${currentSection === "contact" ? "nav__item--is_active" : ""}`}>
                        <a href="#contact" onClick={handleClick}>
                            contact us
                        </a>
                    </li>
                </ul>
            </nav>
            <HamburberMenu currentSection={currentSection} setCurrentSection={setCurrentSection} />
        </>
    );
};

export default Navigation;
