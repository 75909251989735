import React from "react";

import "./card.scss";

const Card = ({ center, logo, alt, title, children, className }) => {
    return (
        <div 
            className={`card ${center ? "card--center" : ""} ${className ? className : ""}`}
        >
            <div className="card__header">
                <h4 className="card__title title_bold">{title}</h4>
                <img className="card__logo" src={logo} alt={alt} />
            </div>
            <p className="card__text">{children}</p>
        </div>
    );
};

export default Card;
