import React from "react";

import { MainPage } from "./pages";

import "./styles/main.scss";

const App = () => {
  return <MainPage />;
}

export default App;
