import React from "react";

import "./a-button.scss";

const AButton = ({ children, href, onClick, className, background, color, border }) => {
    return (
        <a 
            className={`btn ${className ? className : ""}`}
            href={href} 
            onClick={onClick} 
            style={{ background, color, border }}
        >
            {children}
        </a>
    );
};

export default AButton;
