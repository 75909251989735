import React, { useState } from "react";

import { AboutUsSection, MainSection, ServicesSection, WorkWithUsSection, MissionSection } from "./sections";

import "./main-page.scss";

const MainPage = () => {
    const [currentSection, setCurrentSection] = useState("home");

    return (
        <>
            <MainSection currentSection={currentSection} setCurrentSection={setCurrentSection} />
            <AboutUsSection currentSection={currentSection} />
            <ServicesSection currentSection={currentSection} />
            <WorkWithUsSection currentSection={currentSection} />
            <MissionSection currentSection={currentSection} />
        </>
    );
};

export default MainPage;
